import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import throttle from 'lodash/throttle';
import { useCrosshairDispatch } from '../../../hooks/useCrosshairStore/useCrosshairStore';
import './Crosshair.css';
import { checkWithinBounds } from '../GanttChart/ganttHelpers';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../../lib/constants';
import usePrevious from '../../../hooks/usePrevious/usePrevious';
import { useSelector as reduxUseSelector } from 'react-redux';
import { useViewId } from '../../../contexts/ViewIdContext/ViewIdContext';
import PropTypes from 'prop-types';

export const getCoords = (x, y, container, showMultipleViews) => {
  const { x: containerX, y: containerY } = container.getBoundingClientRect();
  return {
    x: x + container.scrollLeft - containerX,
    y: showMultipleViews ? y - containerY + container.offsetTop : y,
  };
};

export const getInitCoords = (container, showMultipleViews, index) => {
  return {
    x: (container?.scrollLeft || 0) + (container?.clientWidth || 2) / 2 - index * 10,
    y:
      (showMultipleViews
        ? container?.offsetTop + (container?.clientHeight || 2) / 2
        : container?.getBoundingClientRect().top + (container?.clientHeight || 2) / 2) -
      index * 10,
  };
};

export const checkAndResetCoords = (container, subContainer, coords, showMultipleViews, index, setCoords) => {
  if (
    showMultipleViews &&
    container &&
    subContainer &&
    !!container?.style?.getPropertyValue('--time-scale-hour') &&
    (coords?.y > container?.offsetTop + container?.getBoundingClientRect().height ||
      coords?.x > subContainer?.scrollWidth)
  ) {
    setCoords(getInitCoords(container, showMultipleViews, index));
  }
};

/**
 * Vertical and horizontal lines that span the containing div, including
 * total scroll space, with horizontal line remaining stationary on scroll
 * and vertical line moving with chart on scroll
 * @param {Object} props
 * @param {Element} props.containerRef ref for containing element
 * @param {String} props.height CSS string for height in px
 * @param {Number} props.prevScrollPositions position of previous scroll
 *  this is needed due to sometimes when component mounts container hasn't been updated with scroll yet
 * @returns CrossHairButton component
 */
function Crosshair({
  containerRef,
  subContainerRef,
  height,
  index,
  active,
  position,
  prevScrollPositions,
  sessionStoragePosition,
  setSessionStoragePosition,
  setSessionStorageActiveCrosshair,
}) {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const [rerender, setRerender] = useState(false);
  const prevClientWidth = usePrevious(containerRef?.current?.clientWidth);
  const { id } = useViewId();
  const viewManagerReducer = reduxUseSelector((state) => state.viewManagerReducer);

  let viewSize;
  let isMaximized;
  let viewWindows;
  let maximizedViews;
  if (showMultipleViews) {
    viewWindows = viewManagerReducer?.viewWindows;
    maximizedViews = viewManagerReducer?.maximizedViews;
    viewSize = viewWindows.find((view) => view.id === id)?.viewSize;
    isMaximized = maximizedViews.includes(id);
  }

  const { updateActiveCrosshair, updateCrosshairPosition } = useCrosshairDispatch();
  const container = containerRef?.current;
  const subContainer = subContainerRef?.current;
  const rect = useMemo(() => container?.getBoundingClientRect() || { top: 0 }, [container]);

  const coordsRef = useRef(null);

  const [coords, setCoords] = useState(position ?? null);

  const _setCoords = (coords) => {
    coordsRef.current = coords;
    setCoords(coords);
  };

  useEffect(() => {
    if (container !== null && !coords) {
      _setCoords(getInitCoords(container, showMultipleViews, index));
    }
  }, [container, container?.scrollLeft, container?.clientWidth, container?.clientHeight, coords]);

  /**
   * When view is maximized or auto-arranged there's a delay in clientWidth on the ref being updated
   * This will force a rerender to get the correct clientWidth
   */
  useEffect(() => {
    if (container?.clientWidth !== prevClientWidth) {
      setRerender(!rerender);
    }
  }, [isMaximized, viewSize?.width, viewSize?.height, showMultipleViews]);

  useEffect(() => {
    checkAndResetCoords(container, subContainer, coords, showMultipleViews, index, _setCoords);
  }, [
    container?.getBoundingClientRect().height,
    container?.clientHeight,
    container?.offsetTop,
    coords?.y,
    isMaximized,
  ]);

  const getInitX = useCallback(() => {
    return (
      ((prevScrollPositions?.scrollLeft && prevScrollPositions.scrollLeft < container?.clientWidth
        ? prevScrollPositions.scrollLeft
        : container?.scrollLeft) ||
        container?.scrollLeft ||
        0) +
      (container?.clientWidth || 2) / 2 -
      index * 10
    );
  }, [container, container?.scrollLeft, prevScrollPositions]);

  const updateCoords = (
    container,
    subContainer,
    sessionStoragePosition,
    rect,
    index,
    _setCoords,
    setSessionStoragePosition,
  ) => {
    if (subContainer !== null && sessionStoragePosition?.x > subContainer?.scrollWidth) {
      _setCoords({
        x: (container?.scrollLeft || 0) + (container?.clientWidth || 2) / 2 - index * 10,
        y: rect.top + (container?.clientHeight || 2) / 2 - index * 10,
      });
      if (container?.scrollLeft > 0) {
        setSessionStoragePosition({ index, coords: coordsRef.current });
      }
    } else {
      _setCoords(sessionStoragePosition);
    }
  };

  const initializeCoords = (container, coords, getInitX, rect, index, _setCoords) => {
    if (container !== null && !coords) {
      _setCoords({
        x: getInitX(),
        y: rect.top + (container?.clientHeight || 2) / 2 - index * 10,
      });
    }
  };

  useEffect(() => {
    if (!showMultipleViews) {
      if (container !== null && sessionStoragePosition) {
        updateCoords(
          container,
          subContainer,
          sessionStoragePosition,
          rect,
          index,
          _setCoords,
          setSessionStoragePosition,
        );
      } else {
        initializeCoords(container, coords, getInitX, rect, index, _setCoords);
      }
    }
  }, [container, container?.scrollLeft, getInitX]);

  useEffect(() => {
    if (container && active) {
      container.addEventListener('mousedown', onMouseDown);
      container.addEventListener('mouseup', onMouseUp);
      container.addEventListener('mousemove', onMouseMove);
    }

    if (container && !active) {
      container.removeEventListener('mousedown', onMouseDown);
      container.removeEventListener('mouseup', onMouseUp);
      container.removeEventListener('mousemove', onMouseMove);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousedown', onMouseDown);
        container.removeEventListener('mouseup', onMouseUp);
        container.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [container, active]);

  const onMouseDown = useCallback(
    (e) => {
      const { buttons, button, clientX: x, clientY: y } = e;
      if ((buttons === 2 || button === 2) && checkWithinBounds(x, y, container)) {
        _setCoords(getCoords(x, y, container, showMultipleViews));
      }
    },
    [container, showMultipleViews],
  );

  const onMouseUp = useCallback(
    (e) => {
      const { buttons, button, clientX: x, clientY: y } = e;
      if ((buttons === 2 || button === 2) && checkWithinBounds(x, y, container)) {
        showMultipleViews
          ? updateCrosshairPosition({ index, coords: coordsRef.current })
          : setSessionStoragePosition({ index, coords: coordsRef.current });
      }
    },
    [coords, container],
  );

  const onMouseMove = useCallback(
    throttle((e) => {
      const { buttons, button, clientX: x, clientY: y } = e;
      if ((buttons === 2 || button === 2) && checkWithinBounds(x, y, container)) {
        // Prevent highlighting text while dragging
        e.preventDefault();
        _setCoords(getCoords(x, y, container, showMultipleViews));
      }
    }, 50),
    [container],
  );

  const onClick = () => {
    if (!showMultipleViews) {
      setSessionStorageActiveCrosshair(index);
    }
    updateActiveCrosshair({ activeCrosshair: index });
  };

  if (!container || !coords) {
    return <></>;
  }

  return (
    <>
      <div
        className="crosshair-ver-line-wrapper"
        onClick={onClick}
        style={{
          // -7 to center the crosshair given the width of the wrapper is 14px
          transform: `translateX(${coords.x - 7}px)`,
          height,
        }}
        data-cy="crosshair-ver-line"
      >
        <div
          className={`crosshair-ver-line${active ? ' active-crosshair' : ''}`}
          style={{
            height,
          }}
        />
      </div>
      <div
        className="crosshair-hor-line-wrapper"
        onClick={onClick}
        style={{
          top: `${coords.y - 7}px`,
          width: container?.clientWidth ? `${container.clientWidth}px` : '100%',
        }}
        data-cy="crosshair-hor-line"
      >
        <div
          className={`crosshair-hor-line${active ? ' active-crosshair' : ''}`}
          style={{
            width: container?.clientWidth ? `${container.clientWidth}px` : '100%',
          }}
        />
      </div>
    </>
  );
}

Crosshair.propTypes = {
  containerRef: PropTypes.object,
  subContainerRef: PropTypes.object,
  height: PropTypes.string,
  index: PropTypes.number,
  active: PropTypes.bool,
  position: PropTypes.object,
  prevScrollPositions: PropTypes.object,
  sessionStoragePosition: PropTypes.object,
  setSessionStoragePosition: PropTypes.func,
  setSessionStorageActiveCrosshair: PropTypes.func,
};

export default Crosshair;
