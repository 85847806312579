import React, { forwardRef, useEffect, useCallback, useRef, useMemo } from 'react';
import Crosshair from '../Crosshair';
import { useCrosshairStore, useCrosshairDispatch } from '../../../../hooks/useCrosshairStore/useCrosshairStore';
import { SessionStorageKeys, Treatment } from '../../../../lib/constants';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';

/**
 * Container component for the Crosshair feature.
 * @param {Object} props - The component props.
 * @param {Object} ref - The component ref.
 * @returns {JSX.Element|null} The rendered CrosshairContainer component.
 */
const CrosshairContainer = (props, ref) => {
  const { ganttContentRef, bottomContentRef } = ref;
  const { nowLineHeight, prevScrollPositions, showHeader } = props;
  const { isCrosshairActive, crosshairCount, activeCrosshair, crosshairPositions } = useCrosshairStore();
  const { updateActiveCrosshair, updateCrosshair, updateCrosshairCount, clearCrosshairStore } = useCrosshairDispatch();
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const activeCrosshairRef = useRef(activeCrosshair);

  const crosshairSessionStorage = useMemo(() => {
    const crosshairSession = sessionStorage.getItem(SessionStorageKeys.CROSSHAIR);
    return crosshairSession ? JSON.parse(crosshairSession) : null;
  }, [isCrosshairActive, crosshairCount, activeCrosshair, crosshairPositions]);

  /**
   * Sets the position of the crosshair in the session storage.
   * @param {number} index - The index of the crosshair.
   * @param {Object} coords - The coordinates of the crosshair.
   */

  const getSessionStorage = () => {
    return crosshairSessionStorage ?? {};
  };

  const setSessionStoragePosition = useCallback(
    ({ index, coords }) => {
      const crosshairSessionStorage = getSessionStorage();
      const currentActiveCrosshair = activeCrosshairRef.current;
      if (currentActiveCrosshair === index) {
        const updatedPositions = crosshairSessionStorage?.crosshairPositions ?? [];
        updatedPositions[index] = coords;
        const updatedCrosshairSessionStorage = crosshairSessionStorage ?? {};
        updatedCrosshairSessionStorage.crosshairPositions = updatedPositions;
        sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(updatedCrosshairSessionStorage));
      }
    },
    [getSessionStorage, activeCrosshairRef.current],
  );

  /**
   * Sets the active crosshair in session storage.
   * @param {number} index - The index of the active crosshair.
   */
  const setSessionStorageActiveCrosshair = useCallback(
    (index) => {
      const updatedCrosshairSessionStorage = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CROSSHAIR) || '{}');
      updatedCrosshairSessionStorage.activeCrosshair = index;
      sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(updatedCrosshairSessionStorage));
    },
    [getSessionStorage],
  );

  /**
   * When setting number of crosshairs, if current active crosshair
   * isn't displayed, reset to first crosshair.
   */
  useEffect(() => {
    if (activeCrosshair >= crosshairCount) {
      updateActiveCrosshair(0);
    }
  }, [crosshairCount]);

  // Reset crosshair when component unmounts
  useEffect(() => {
    return () => {
      if (!showMultipleViews) {
        clearCrosshairStore();
      }
    };
  }, []);

  useEffect(() => {
    activeCrosshairRef.current = activeCrosshair;
  }, [activeCrosshair]);

  useEffect(() => {
    if (!showMultipleViews) {
      if (crosshairSessionStorage) {
        const { activeCrosshair, crosshairCount, isCrosshairActive } = crosshairSessionStorage;

        if (activeCrosshair) {
          updateActiveCrosshair({ activeCrosshair });
        }

        if (crosshairCount) {
          updateCrosshairCount({ crosshairCount });
        }

        if (isCrosshairActive) {
          updateCrosshair({ isCrosshairActive });
        }
      }
    }
  }, []);

  return isCrosshairActive
    ? Array.from(Array(crosshairCount)).map((_, i) => {
        return (
          <Crosshair
            containerRef={bottomContentRef}
            height={nowLineHeight}
            subContainerRef={ganttContentRef}
            prevScrollPositions={prevScrollPositions}
            index={i}
            key={`crosshair-${i}`}
            active={activeCrosshair === i}
            showHeader={showHeader}
            position={crosshairPositions?.[i]}
            sessionStoragePosition={
              crosshairSessionStorage?.crosshairPositions ? crosshairSessionStorage.crosshairPositions[i] : null
            }
            setSessionStoragePosition={setSessionStoragePosition}
            setSessionStorageActiveCrosshair={setSessionStorageActiveCrosshair}
          />
        );
      })
    : null;
};

export default forwardRef(CrosshairContainer);
