import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import {
  getGanttScaleCssClassName,
  getTimelineStartDateTime,
  getTimelineEndDateTime,
  getRowTimes,
  getLineHeight,
} from './ganttHelpers';
import LineOfFlight from '../LineOfFlight/LineOfFlight';

import './GanttChart.css';
import '../../../styles/ganttScale.css';
import { ScrollLocalStorageElementId, Treatment, RemInPixels, NoDataMessage } from '../../../lib/constants';
import { getTimeDifference, getDatetimeUtcNowString } from '../../../lib/dateTimeUtils';
import { setComponentScroll, getComponentScroll, convertRemToPixels, getLineOfFlightHeight } from '../../../lib/utils';
import debounce from 'lodash/debounce';
import TimelineContainer from './TimelineContainer/TimelineContainer';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { useViewId } from '../../../contexts/ViewIdContext/ViewIdContext';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import LoadingIndicator from '../../Shared/LoadingIndicator/LoadingIndicator';
import GanttChartVirtualizer from './GanttChartVirtualizer/GanttChartVirtualizer';
import { useGanttConfig } from '../../../hooks/useGanttConfig/useGanttConfig';
import { useCrosshairStore } from '../../../hooks/useCrosshairStore/useCrosshairStore';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import { useSelectedItemDispatch } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import NoDataMessageHeader from '../NoDataMessageHeader/NoDataMessageHeader';
import { useNotificationUpdate } from '../../../contexts/NotificationsContext/NotificationsContext';
import { ToastType } from '../../Shared/NotificationToast/NotificationToast';
import AircraftLabel from './AircraftLabel/AircraftLabel';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import usePrevious from '../../../hooks/usePrevious/usePrevious';
import SearchOverlay from '../SearchFlightNumber/SearchOverlay/SearchOverlay';
import { useFocusedFlightLegStore } from '../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import { TailNumberIndicatorTypes } from './AircraftLabel/TailNumberIndicators';
import CrosshairContainer from '../CrosshairMode/CrosshairContainer/CrosshairContainer';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import {
  useUserPreferencesData,
  useUserPreferencesFunctions,
} from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import { useConfigStore } from '../../../hooks/useConfigStore/useConfigStore';
import {
  useViewConfigurationDispatch,
  useViewConfigurationStore,
} from '../../../hooks/useViewConfigurationStore/useViewConfigurationStore';
import PropTypes from 'prop-types';
import { setPuckSizeGanttRowHeight } from './ganttHelpersUtils';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const defaultNowLinePosition = 4;

const isCollapsingRowsEnabled = (isSwapModeActive, swapModeCollapsingRowsFlag, collapsingRowsFlag) => {
  return (!isSwapModeActive || swapModeCollapsingRowsFlag) && collapsingRowsFlag;
};

/**
 * GanttChart component
 */
const GanttChart = ({
  startDate,
  endDate,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  summaryPanelHeight,
  handleChangeActivityKey,
  showHeader = true,
  viewSize,
}) => {
  // custom hook for accessing Gantt scaling settings.
  const { state: userPreferencesState } = useUserPreferencesData();
  const { updatePuckSizePreference } = useUserPreferencesFunctions();
  const { activeConfigurationId } = useConfigStore();

  const { isSwapModeActive } = useSwapModeStore();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [aircraftLabelWidth, setAircraftLabelWidth] = useState(null);
  const [ganttRowHeightPx, setGanttRowHeightPx] = useState(0);
  const [enhancedGanttRowHeight, setEnhancedGanttRowHeight] = useState(0);

  const [startDateRange, setStartDateRange] = useState(startDate);
  const [endDateRange, setEndDateRange] = useState(endDate);
  const [enableScrollUpdateOnChange, setEnableScrollUpdateOnChange] = useState(false);
  const { isFocused, focusedFlightLeg, highlightFlightLeg } = useFocusedFlightLegStore();
  const { clearSelectedFlightDetails } = useSelectedItemDispatch();
  const { updateViewConfiguration } = useViewConfigurationDispatch();
  const viewConfigurationData = useViewConfigurationStore();
  const currentActiveViewId = useViewId();

  let prevScrollPositions = getComponentScroll(currentActiveViewId.id, ScrollLocalStorageElementId.GANTT_CHART);

  const previousFilterDates = usePrevious([startDate, endDate]);
  // @deprecated - (BA) useFeatureFlag hook instead of obsolete useTreatments
  const { showFeature } = useFeatureFlag();
  const virtualizeFlag = showFeature(Treatment.GANTT_VIRTUALIZATION);
  const collapsingRowsFlag = showFeature(Treatment.COLLAPSING_ROWS);
  const swapModeCollapsingRowsFlag = showFeature(Treatment.SWAP_MODE_COLLAPSING_ROWS);
  const tailNumberIndicatorsFlag = showFeature(Treatment.TAIL_NUMBER_INDICATORS);
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);

  const collapsingRowsEnabled = isCollapsingRowsEnabled(
    isSwapModeActive,
    swapModeCollapsingRowsFlag,
    collapsingRowsFlag,
  );

  const { loading: loadingGanttConfig, ganttConfig, errors } = useGanttConfig();

  const tailNumberIndicators = useMemo(() => {
    const tailNumberIndicators = {};
    if (tailNumberIndicatorsFlag && Object.keys(ganttConfig).length) {
      Object.keys(ganttConfig).forEach((ac) => {
        tailNumberIndicators[ac] = {
          [TailNumberIndicatorTypes.ETOPS_INDICATOR]: ganttConfig[ac][TailNumberIndicatorTypes.ETOPS_INDICATOR],
          [TailNumberIndicatorTypes.PLUG]: ganttConfig[ac][TailNumberIndicatorTypes.PLUG],
          [TailNumberIndicatorTypes.WIFI_INDICATOR]: ganttConfig[ac][TailNumberIndicatorTypes.WIFI_INDICATOR],
          [TailNumberIndicatorTypes.CAT_STATUS_INDICATOR]:
            ganttConfig[ac][TailNumberIndicatorTypes.CAT_STATUS_INDICATOR],
          [TailNumberIndicatorTypes.MISSING_CERTIFICATES_INDICATOR]:
            ganttConfig[ac][TailNumberIndicatorTypes.MISSING_CERTIFICATES_INDICATOR],
        };
      });
    }
    return tailNumberIndicators;
  }, [ganttConfig, tailNumberIndicatorsFlag]);

  // Track page view
  const { trackPageView } = useAppInsightsContext();

  useEffect(() => {
    trackPageView('GanttViewPage');
  }, [trackPageView]);

  const rowTimes = useMemo(() => {
    if (!highlightFlightLeg && collapsingRowsEnabled && Object.keys(ganttConfig).length) {
      const updatedRowTimes = getRowTimes(ganttConfig, startDate, endDate);
      return updatedRowTimes;
    } else {
      return {};
    }
  }, [ganttConfig, highlightFlightLeg, startDate, endDate, collapsingRowsEnabled]);

  const showRows = useMemo(() => {
    if (
      !highlightFlightLeg &&
      collapsingRowsEnabled &&
      Object.keys(rowTimes).length &&
      startDateRange &&
      endDateRange
    ) {
      const updatedShowRows = {};
      Object.keys(rowTimes).forEach((aircraft) => {
        if (rowTimes[aircraft].length === 1) {
          updatedShowRows[aircraft] = [true];
          return;
        }
        rowTimes[aircraft].forEach((row) => {
          if (
            dayjs(row.startTime).isSameOrAfter(endDateRange, 'minute') ||
            dayjs(row.endTime).isSameOrBefore(startDateRange, 'minute')
          ) {
            updatedShowRows[aircraft] = [...(updatedShowRows[aircraft] ? updatedShowRows[aircraft] : []), false];
          } else {
            updatedShowRows[aircraft] = [...(updatedShowRows[aircraft] ? updatedShowRows[aircraft] : []), true];
          }
        });
      });
      return updatedShowRows;
    } else {
      return {};
    }
  }, [startDateRange, endDateRange, rowTimes, collapsingRowsEnabled, highlightFlightLeg]);

  // Notification toast for ground events error
  const updateNotification = useNotificationUpdate();

  useEffect(() => {
    if (errors.groundEvents && !errors.flights) {
      const message =
        'Unable to retrieve ground event information at this time.\nPlease refresh or contact ITS Service Desk.';
      updateNotification(ToastType.ERROR, message, true, false);
    }
    if (errors.groundEvents && errors.flights) {
      clearSelectedFlightDetails();
    }
  }, [errors.groundEvents, errors.flights, updateNotification, clearSelectedFlightDetails]);

  const aircraftList = useMemo(() => Object.keys(ganttConfig), [ganttConfig]);

  /**
   * Boolean representing whether the gantt chart should display a no data message
   */
  const showNoDataMessage = useMemo(() => {
    return !loadingGanttConfig && (aircraftList.length === 0 || (errors.flights && errors.groundEvents));
  }, [loadingGanttConfig, aircraftList, errors.flights, errors.groundEvents]);

  /**
   * @returns the message to display in the NoDataMessageHeader
   */
  const getNoDataMessage = () => {
    if (errors.flights && errors.groundEvents) {
      return NoDataMessage.FLIGHTS_AND_GROUND_EVENTS_ERROR;
    } else if (errors.flights) {
      return NoDataMessage.FLIGHTS_ERROR;
    } else {
      return NoDataMessage.FILTER_YIELDS_NO_DATA;
    }
  };

  const ganttChartContainerRef = useRef(null);
  const timelineContainerRef = useRef();

  // useCrosshairStore hook
  const { isCrosshairActive } = useCrosshairStore();

  // handler for setting crosshair-active
  const crosshairactiveHandler = () => (isCrosshairActive ? 'crosshair-active' : '');
  // handler for setting swap-mode-active
  const swapModeActiveHandler = () => (isSwapModeActive ? 'swap-mode-active' : '');

  /**
   * Number of hours that needs to be displayed on the gantt
   */
  const hoursToDisplay = useMemo(() => {
    if (enableEnhancedScalingFlag) {
      return viewConfigurationData.hoursBefore + viewConfigurationData.hoursAfter;
    }
    return viewConfigurationData.timelineHours;
  }, [
    enableEnhancedScalingFlag,
    viewConfigurationData.hoursBefore,
    viewConfigurationData.hoursAfter,
    viewConfigurationData.timelineHours,
  ]);

  /**
   * Returns a memoized function to handle screen width changes
   */
  const handleScreenResize = useMemo(
    () =>
      debounce(() => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
      }, 500),
    [],
  );

  // Disable context menu while crosshair is active
  useEffect(() => {
    if (isCrosshairActive) {
      document.oncontextmenu = () => false;
    } else {
      document.oncontextmenu = null;
    }
    return () => (document.oncontextmenu = null);
  }, [isCrosshairActive]);

  /**
   * Side-effect to remove and cancel timeline resizing after gantt is unmounted
   */
  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      handleScreenResize.cancel();
      window.removeEventListener('resize', handleScreenResize);
    };
  }, [handleScreenResize]);

  /**
   * Returns the width (rem) of one hour of time on the gantt that
   * fits the number of hours, hoursToDisplay, on the current gantt screen
   *
   * oneHourRemWidth - one hour in rem
   * oneHourRemWidth - 60 minutes in rem
   */
  const [oneHourRemWidth, oneMinuteRemWidth] = useMemo(() => {
    if (aircraftLabelWidth) {
      const ganttWidth = screenWidth / RemInPixels.ONE_REM_IN_PX;
      const ganttContentWidth = ganttWidth - aircraftLabelWidth;
      return [ganttContentWidth / hoursToDisplay, ganttContentWidth / hoursToDisplay / 60];
    }
    return [null, null];
  }, [hoursToDisplay, aircraftLabelWidth, screenWidth]);

  /**
   * Returns loading for all necessary values needed to render the gantt
   */
  const loading = useMemo(() => {
    const finishedLoading =
      aircraftLabelWidth !== null &&
      hoursToDisplay !== null &&
      oneHourRemWidth !== null &&
      oneMinuteRemWidth !== null &&
      !loadingGanttConfig &&
      (!collapsingRowsEnabled || aircraftList.length === 0 || highlightFlightLeg || Object.keys(showRows).length > 0);

    return !finishedLoading;
  }, [
    aircraftLabelWidth,
    hoursToDisplay,
    oneHourRemWidth,
    oneMinuteRemWidth,
    loadingGanttConfig,
    ganttConfig,
    showRows,
    collapsingRowsEnabled,
  ]);

  /**
   * Saves CSS width and height values of gantt to be used for dynamically calculating
   * other element's CSS values relative to scaling, window size, etc.
   * - Saves the aircraft label's width to be used in calculating the width of the gantt
   * - Saves gantt row height to be used for virtualizing gantt chart
   */
  useEffect(() => {
    if (ganttChartContainerRef?.current) {
      const element = ganttChartContainerRef.current;
      const aircraftWidth = tailNumberIndicatorsFlag
        ? window.getComputedStyle(element).getPropertyValue('--aircraft-container-width-with-indicators')
        : window.getComputedStyle(element).getPropertyValue('--aircraft-container-width');
      const ganttHeight = window.getComputedStyle(element).getPropertyValue('--gantt-row-height');

      setGanttRowHeightPx(convertRemToPixels(ganttHeight));
      setAircraftLabelWidth(parseFloat(aircraftWidth));
    }
  }, [ganttChartContainerRef, viewConfigurationData.puckSize]);

  // DOM reference to .bottom-content div
  const bottomContentRef = useRef(null);

  // Initially reading userPreferences data from the api call to setup redux viewConfiguration store
  useEffect(() => {
    if (activeConfigurationId != null && activeConfigurationId > 0 && !viewConfigurationData.defaultConfig) {
      return;
    }
    if (!userPreferencesState.loading && viewConfigurationData.defaultConfig && userPreferencesState) {
      const newViewConfiguration = {
        puckSize: null,
        timelineHours: userPreferencesState.timelineHours.timelineHours,
        hoursBefore: userPreferencesState.ganttViewScaling.hoursBefore,
        hoursAfter: userPreferencesState.ganttViewScaling.hoursAfter,
        numberOfAircrafts: userPreferencesState.ganttViewScaling.numberOfAircrafts,
      };
      updateViewConfiguration(newViewConfiguration);
    }
  }, [userPreferencesState.loading, viewConfigurationData.defaultConfig]);

  // calculate and set state for Enhanced Gantt Row Height
  useEffect(() => {
    setPuckSizeGanttRowHeight(
      enableEnhancedScalingFlag,
      bottomContentRef,
      viewConfigurationData,
      updateViewConfiguration,
      updatePuckSizePreference,
      setEnhancedGanttRowHeight,
      summaryPanelHeight,
    );
  }, [
    bottomContentRef,
    viewConfigurationData.numberOfAircrafts,
    viewConfigurationData.puckSize,
    showRows,
    collapsingRowsEnabled,
    enableEnhancedScalingFlag,
    summaryPanelHeight,
    screenHeight,
    viewSize,
  ]);

  // DOM reference to .gantt-content div
  const [ganttContentRef, setGanttContentRef] = useState({ current: null });

  /**
   * Assigns CSS properties for other components to scale to the timeline width
   */
  useEffect(() => {
    if ((oneHourRemWidth && oneMinuteRemWidth, bottomContentRef?.current)) {
      bottomContentRef.current.style.setProperty('--time-scale-hour', `${oneHourRemWidth}rem`);
      bottomContentRef.current.style.setProperty('--time-scale-minute', `${oneMinuteRemWidth}rem`);
    }
  }, [oneHourRemWidth, oneMinuteRemWidth, bottomContentRef?.current]);

  const updateGanttContentRef = useCallback((ref) => {
    if (ref !== null) {
      setGanttContentRef({ current: ref });
    }
  }, []);

  const nowLineHeight = useMemo(() => {
    let updatedNowLineHeight = 0;
    if (!highlightFlightLeg && collapsingRowsEnabled && showRows && Object.keys(showRows).length > 0) {
      Object.keys(showRows).forEach((aircraft) => {
        updatedNowLineHeight += showRows[aircraft].reduce((acc, val) => (val ? acc + 1 : acc), 0);
      });
    } else {
      Object.keys(ganttConfig).forEach((aircraft) => {
        updatedNowLineHeight += ganttConfig[aircraft].totalRows;
      });
    }
    return updatedNowLineHeight;
  }, [
    collapsingRowsEnabled,
    showRows,
    ganttConfig,
    focusedFlightLeg,
    highlightFlightLeg,
    viewConfigurationData.numberOfAircrafts,
  ]);

  // Start and end date times of the gantt chart timeline
  const timelineStartDateTime = useMemo(
    () => getTimelineStartDateTime(startDate, ganttConfig),
    [startDate, ganttConfig],
  );
  const timelineEndDateTime = useMemo(() => getTimelineEndDateTime(endDate, ganttConfig), [endDate, ganttConfig]);
  const nowUtcMinutesFromStart = useMemo(
    () => getTimeDifference(timelineStartDateTime, getDatetimeUtcNowString()),
    [timelineStartDateTime, ganttConfig],
  );
  // Number of total hours to show on the timeline
  // Set to either the duration of the dataset (pucks) or the timeline scale setting
  const additionalHours =
    enableEnhancedScalingFlag &&
    (hoursToDisplay > getTimeDifference(timelineStartDateTime, timelineEndDateTime, 'hours') ||
      getTimeDifference(timelineStartDateTime, timelineEndDateTime, 'hours') >= 72)
      ? 24
      : 0;

  const timelineHours = Math.max(
    getTimeDifference(timelineStartDateTime, timelineEndDateTime, 'hours'),
    parseInt(hoursToDisplay) + parseInt(additionalHours),
  );

  /**
   * Boolean representing whether the now line needs to be displayed on the screen.
   */
  const showNowLine = useMemo(() => {
    return nowUtcMinutesFromStart < timelineHours * 60 && nowUtcMinutesFromStart > 0;
  }, [timelineHours, nowUtcMinutesFromStart]);

  const extendedhoursBefore = Math.max(viewConfigurationData.hoursBefore - Math.floor(nowUtcMinutesFromStart / 60), 0);

  const calculateExtendedStartTimeHours = (showNowLine, enableEnhancedScalingFlag, extendedhoursBefore) => {
    if (showNowLine && enableEnhancedScalingFlag) {
      // +1 for the if statement
      return extendedhoursBefore;
    }
    return 0;
  };

  const extendedStartTimeHours = calculateExtendedStartTimeHours(
    showNowLine,
    enableEnhancedScalingFlag,
    extendedhoursBefore,
  );

  /**
   * @returns the style object for the now line
   */

  const getNowLineStyle = useCallback(() => {
    if (enableEnhancedScalingFlag && extendedStartTimeHours != 0) {
      return {
        left: `calc(${nowUtcMinutesFromStart + extendedStartTimeHours * 60} * var(--time-scale-minute))`,
        height: `calc(${nowLineHeight} * ${enhancedGanttRowHeight}px)`,
        minHeight: '100%',
      };
    } else if (!enableEnhancedScalingFlag) {
      return {
        left: `calc(${nowUtcMinutesFromStart} * var(--time-scale-minute))`,
        height: `calc(${nowLineHeight} * var(--gantt-row-height))`,
        minHeight: '100%',
      };
    } else {
      return {
        left: `calc(${nowUtcMinutesFromStart} * var(--time-scale-minute))`,
        height: `calc(${nowLineHeight} *  ${enhancedGanttRowHeight}px)`,
        minHeight: '100%',
      };
    }
  }, [
    nowUtcMinutesFromStart,
    nowLineHeight,
    enableEnhancedScalingFlag,
    extendedStartTimeHours,
    enhancedGanttRowHeight,
  ]);

  /**
   * In pixels, the now line left position
   */
  const nowLineLeftScrollPosition = useMemo(() => {
    let nowLinePosition = defaultNowLinePosition;
    if (showNowLine) {
      if (enableEnhancedScalingFlag && viewConfigurationData.hoursBefore != 0) {
        nowLinePosition = viewConfigurationData.hoursBefore;
      }
      const fourHoursInPx = oneHourRemWidth * RemInPixels.ONE_REM_IN_PX * nowLinePosition;
      return oneMinuteRemWidth * nowUtcMinutesFromStart * RemInPixels.ONE_REM_IN_PX - fourHoursInPx;
    } else {
      return 0;
    }
  }, [oneMinuteRemWidth, oneHourRemWidth, showNowLine, nowUtcMinutesFromStart]);
  /**
   * Side-effect to reset the gantt auto-scrolling flag if the filter dates have changed
   */
  useEffect(() => {
    if (!enableEnhancedScalingFlag) {
      return;
    }
    const filterDatesHaveChanged = previousFilterDates?.[0] !== startDate || previousFilterDates?.[1] !== endDate;
    if (filterDatesHaveChanged) {
      setEnableScrollUpdateOnChange(false);
      handleAutoScroll(nowLineLeftScrollPosition);
    }
  }, [startDate, endDate, previousFilterDates]);

  const handleAutoScroll = (scrollLeft) => {
    if (bottomContentRef.current == null) {
      return;
    }

    if (!enableScrollUpdateOnChange) {
      setTimeout(() => {
        if (bottomContentRef.current != null) {
          bottomContentRef.current.scrollLeft = scrollLeft;
        }
        setEnableScrollUpdateOnChange(true);
      }, 200);
    } else {
      bottomContentRef.current.scrollLeft = scrollLeft;
    }
  };

  /**
   * Callback to synchronous scroll of the gantt chart content and the timeline
   * @param {*} e
   */
  const handleScroll = useCallback(
    (e) => {
      if (e.target.className === 'bottom-content' && timelineContainerRef?.current) {
        timelineContainerRef.current.scrollLeft = e.target.scrollLeft;
        setComponentScroll(
          currentActiveViewId.id,
          ScrollLocalStorageElementId.GANTT_CHART,
          e.target.scrollTop,
          e.target.scrollLeft,
        );
      }
    },
    [timelineContainerRef],
  );

  // Update the scroll position of the gantt chart to reflect hoursBefore nowLine when hoursBefore is changed in enhanced Scaling modal.
  // this works when enhanced scaling feature flag is enabled
  useEffect(() => {
    if (loading || bottomContentRef?.current == null) {
      return;
    }
    let scrollLeftValue = nowLineLeftScrollPosition;

    let shouldScroll =
      nowLineLeftScrollPosition > 0 &&
      (enableEnhancedScalingFlag
        ? viewConfigurationData?.hoursBefore < nowUtcMinutesFromStart / 60
        : nowLineLeftScrollPosition > nowUtcMinutesFromStart / 60);

    if (prevScrollPositions != null && prevScrollPositions.scrollLeft != undefined) {
      if (
        bottomContentRef.current?.scrollLeft > 0 &&
        Math.abs((nowLineLeftScrollPosition - prevScrollPositions.scrollLeft) / nowLineLeftScrollPosition) < 0.02
      ) {
        return;
      }
      scrollLeftValue = prevScrollPositions.scrollLeft;
    }

    if (shouldScroll) {
      handleAutoScroll(scrollLeftValue);
    }
  }, [loading, nowLineLeftScrollPosition]);

  /**
   * Side-effect to auto-scroll the gantt chart
   * When hoursBefore is changed in enhanced scaling modal, we should reset scrolling to hoursBefore on Gantt chart
   * When timelineHours is changed, we should reset scrolling to default 4 hr position.
   */
  useEffect(() => {
    if (loading || !enableScrollUpdateOnChange) {
      return;
    }

    let shouldScroll =
      nowLineLeftScrollPosition > 0 &&
      (enableEnhancedScalingFlag
        ? viewConfigurationData?.hoursBefore < nowUtcMinutesFromStart / 60
        : nowLineLeftScrollPosition > nowUtcMinutesFromStart / 60);

    if (shouldScroll) {
      handleAutoScroll(nowLineLeftScrollPosition);
    }
  }, [loading, oneHourRemWidth, oneMinuteRemWidth]);

  /**
   * Returns the height in pixels of a given aicraft row
   * @param {Number} aircraftIndex - index of nth line of flight row
   * @returns height in pixels
   */
  const getLineOfFlightHeightCallback = useCallback(
    (aircraftIndex) => {
      return getLineOfFlightHeight(
        aircraftIndex,
        aircraftList,
        bottomContentRef,
        showRows,
        ganttConfig,
        collapsingRowsEnabled,
        ganttRowHeightPx,
        enableEnhancedScalingFlag,
        // userPreferencesState,
        viewConfigurationData,
        summaryPanelHeight,
      );
    },
    [
      aircraftList,
      bottomContentRef,
      ganttRowHeightPx,
      showRows,
      ganttConfig,
      collapsingRowsEnabled,
      // userPreferencesState?.ganttViewScaling,
      viewConfigurationData,
      enableEnhancedScalingFlag,
      screenHeight,
    ],
  );
  const getFocusedFlightLegIndex = useMemo(() => {
    if (!isFocused && focusedFlightLeg) {
      const acKeys = Object.keys(ganttConfig);
      return acKeys.findIndex((ac) => ac === focusedFlightLeg?.aircraft);
    }
    return undefined;
  }, [ganttConfig, focusedFlightLeg, isFocused]);

  /**
   * Given an aircraft registration, uses gantt data to render the line of flight
   * @param {string} aircraft - aircraft registration
   * @returns LineOfFlight component within container
   */
  const renderLineOfFlight = useCallback(
    (aircraft) => {
      const lineOfFlightConfig = ganttConfig[aircraft];

      const style = enableEnhancedScalingFlag
        ? {
            width: `calc(${timelineHours} * var(--time-scale-hour))`,
            height: `${
              getLineHeight(showRows[aircraft], ganttConfig[aircraft], !highlightFlightLeg && collapsingRowsEnabled) *
              enhancedGanttRowHeight
            }px`,
            lineHeight: `${
              getLineHeight(showRows[aircraft], ganttConfig[aircraft], !highlightFlightLeg && collapsingRowsEnabled) *
              enhancedGanttRowHeight
            }px`,
          }
        : {
            width: `calc(${timelineHours} * var(--time-scale-hour))`,
            height: `calc(${getLineHeight(
              showRows[aircraft],
              ganttConfig[aircraft],
              !highlightFlightLeg && collapsingRowsEnabled,
            )} * var(--gantt-row-height))`,
            lineHeight: `calc(${getLineHeight(
              showRows[aircraft],
              ganttConfig[aircraft],
              !highlightFlightLeg && collapsingRowsEnabled,
            )} * var(--gantt-row-height))`,
          };

      return (
        <div
          className={`${crosshairactiveHandler()} ${swapModeActiveHandler()} gantt-row ${
            enableEnhancedScalingFlag ? 'enhanced-aircraft-row' : 'aircraft-row'
          }`}
          data-cy={`aircraft-row-${aircraft}`}
          key={`aircraft-row-${aircraft}`}
          style={style}
        >
          <LineOfFlight
            aircraftRegistration={aircraft}
            lineOfFlightConfig={lineOfFlightConfig}
            ganttStartTime={timelineStartDateTime}
            extendedStartTimeHours={extendedStartTimeHours}
            ganttEndTime={timelineEndDateTime}
            openDetailPane={openDetailPane}
            isPaneOpen={isPaneOpen}
            summaryPanelMode={summaryPanelMode}
            handleChangeActivityKey={handleChangeActivityKey}
            oneHourRemWidth={oneHourRemWidth}
            hoursDisplay={hoursToDisplay}
            showRows={showRows[aircraft]}
            collapsingRowsFlag={collapsingRowsEnabled}
            focusedFlightLeg={focusedFlightLeg}
            enhancedGanttRowHeight={enhancedGanttRowHeight}
            totalRows={getLineHeight(
              showRows[aircraft],
              ganttConfig[aircraft],
              collapsingRowsEnabled && !focusedFlightLeg,
            )}
          />
        </div>
      );
    },
    [
      ganttConfig,
      timelineStartDateTime,
      timelineEndDateTime,
      hoursToDisplay,
      oneHourRemWidth,
      showRows,
      enhancedGanttRowHeight,
      screenHeight,
      focusedFlightLeg,
    ],
  );

  /**
   * Returns the i-th element (aircraft registration) from aircraftList
   */
  const getAircraftKeyByIndex = useCallback((i) => aircraftList[i], [aircraftList]);

  /**
   * Given an aircraft registration, usse gantt data to render an aircraft label
   * @param {string} aircraft - aircraft registration
   * @returns Aircraft label component
   */
  const renderAircraftAxisLabel = useCallback(
    (aircraft) => {
      const lineOfFlight = ganttConfig[aircraft];

      return (
        <AircraftLabel
          aircraft={aircraft}
          airline={lineOfFlight.airline}
          subfleetType={lineOfFlight.fleetType}
          totalRows={getLineHeight(
            showRows[aircraft],
            ganttConfig[aircraft],
            collapsingRowsEnabled && !highlightFlightLeg,
          )}
          enhancedGanttRowHeight={enhancedGanttRowHeight}
          className={`${crosshairactiveHandler()} ${swapModeActiveHandler()}`}
          key={`aircraft-label-${aircraft}`}
          tailNumberIndicators={tailNumberIndicators[aircraft]}
          tailNumberIndicatorsFlag={tailNumberIndicatorsFlag}
        />
      );
    },
    [ganttConfig, showRows, collapsingRowsEnabled, enhancedGanttRowHeight],
  );

  /**
   * The crosshairs element that is displayed on the Gantt chart.
   * @type {React.ReactNode}
   */
  const crosshairs = (
    <CrosshairContainer
      ref={{
        ganttContentRef,
        bottomContentRef,
      }}
      nowLineHeight={getNowLineStyle().height}
      prevScrollPositions={prevScrollPositions}
      showHeader={showHeader}
    />
  );

  const onDateTimeChanged = useCallback((data) => {
    if (data.type === 'start') {
      setStartDateRange(data.value);
    }
    if (data.type === 'end') {
      setEndDateRange(data.value);
    }
  }, []);

  let aircraftAxisClasses = 'aircraft-axis';
  if (tailNumberIndicatorsFlag) {
    aircraftAxisClasses += ' tail-number-indicators';
  }

  let content;
  if (loading) {
    content = <LoadingIndicator />;
  } else if (showNoDataMessage) {
    content = <NoDataMessageHeader message={getNoDataMessage()} />;
  } else {
    const aircraftAxisContent = virtualizeFlag ? (
      <GanttChartVirtualizer
        count={aircraftList.length}
        renderFn={renderAircraftAxisLabel}
        estimateRowHeightFn={getLineOfFlightHeightCallback}
        getItemKeyFn={getAircraftKeyByIndex}
        scrollToOffset={prevScrollPositions?.scrollTop}
        scrollElementRef={bottomContentRef}
        scrollToFocusedFlightLegIndex={getFocusedFlightLegIndex}
        id="aircraft-axis"
        dataCyTag="aircraft-axis-virtualizer"
        showRows={showRows}
      />
    ) : (
      aircraftList.map((aircraft) => renderAircraftAxisLabel(aircraft))
    );

    const ganttContent = virtualizeFlag ? (
      <GanttChartVirtualizer
        count={aircraftList.length}
        renderFn={renderLineOfFlight}
        estimateRowHeightFn={getLineOfFlightHeightCallback}
        getItemKeyFn={getAircraftKeyByIndex}
        scrollToOffset={prevScrollPositions?.scrollTop}
        scrollElementRef={bottomContentRef}
        scrollToFocusedFlightLegIndex={getFocusedFlightLegIndex}
        id="gantt"
        dataCyTag="gantt-chart-virtualizer"
        showRows={showRows}
        timelineHours={timelineHours}
      />
    ) : (
      aircraftList.map((aircraft) => renderLineOfFlight(aircraft))
    );

    const searchOverlay = highlightFlightLeg ? (
      <SearchOverlay
        aircraft={focusedFlightLeg.aircraft}
        showRows={showRows}
        ganttConfig={ganttConfig}
        collapsingRowsFlag={collapsingRowsEnabled}
        timelineHours={timelineHours}
        enhancedGanttRowHeight={enhancedGanttRowHeight}
        enableEnhancedScalingFlag={enableEnhancedScalingFlag}
      />
    ) : null;

    content = (
      <>
        <div className="top-content">
          <TimelineContainer
            timelineStartDateTime={timelineStartDateTime}
            extendedStartTimeHours={extendedStartTimeHours}
            timelineHours={timelineHours}
            oneHourRemWidth={oneHourRemWidth}
            timeZoneStartDate={startDate}
            ref={timelineContainerRef}
            hoursToDisplay={hoursToDisplay}
            onDateTimeChanged={collapsingRowsEnabled ? onDateTimeChanged : false}
            tailNumberIndicatorsFlag={tailNumberIndicatorsFlag}
          />
        </div>
        <div
          className="bottom-content"
          data-cy="gantt-chart-bottom-content"
          onScroll={handleScroll}
          ref={bottomContentRef}
        >
          {searchOverlay}
          {crosshairs}
          <div className={aircraftAxisClasses} data-cy="aircraft-axis">
            {aircraftAxisContent}
          </div>
          <div id="gantt-content" className="gantt-content" data-cy="gantt-content" ref={updateGanttContentRef}>
            <div className="test-overlay" />
            {showNowLine && <div id="now-line" style={getNowLineStyle()} data-cy="now-line" />}
            {ganttContent}
          </div>
        </div>
      </>
    );
  }

  return (
    <div
      id="gantt-chart-container"
      className={`gantt-chart-container ${getGanttScaleCssClassName(viewConfigurationData.puckSize)}`}
      data-cy="gantt-chart-container"
      ref={ganttChartContainerRef}
    >
      {content}
    </div>
  );
};

GanttChart.propTypes = {
  summaryPanelHeight: PropTypes.number,
  viewSize: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  openDetailPane: PropTypes.func,
  isPaneOpen: PropTypes.bool,
  summaryPanelMode: PropTypes.string,
  handleChangeActivityKey: PropTypes.func,
  showHeader: PropTypes.bool,
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(GanttChart, 'app-insights-tracking-gantt-chart');
